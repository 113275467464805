#logo {
    width: 180px;
    margin: 60px 0 40px 0;
}
 
#location_SL-img{
    width: auto;
}

#location-img {
    width: 70%;
    height: 100%;
}
