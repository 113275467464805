@import "style";

body {
    min-height: 100vh;
    margin: 0;
    background-image: url('/assets/img/bg-forest.jpg');    
    background-repeat: no-repeat;    
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto Slab', serif;
}
