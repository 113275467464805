@import "base/typography";

:root {
	--white-green: #e1eedf;
	--dark-green: #114c36;
	--light-green: #7A985A;
	--background-blur: #e7eed525;
}

/* Page Lovišče */

.content-section {
    padding: 1rem 2.5rem;
    background-color: var(--background-blur);
    backdrop-filter: blur(.5rem);
    margin-bottom: 5rem;
}

#location_SL-text {
	color: var(--dark-green);
	text-shadow: 2px 1px 5px rgb(143, 143, 143);
}
#location {
	column-span: 2;
}

@media (min-width: 1050px) {
    .location_SL, .location_text_box {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		padding: 2rem 5%;
    }

    .location_SL-img {
		display: flex;
        justify-content: center;
        align-items: center;
		width: 100%;
		height: auto;
    }

    #location_SL-text {
        padding: 0 0 0 2rem;
    }    

	.tab-box {
		width: 50%;
	}

	#location-img {
		margin-left: 4em;
		margin-top: 1em;
	}
}

.location-img {
	text-align: center;
	margin-top: 2rem;
}

#location-img {
	min-width: 350px;
	max-width: 450px;
}

.location_SL-img {
	text-align: center;	
}

#location_SL-img {
	max-width: 600px;
}

.location_text {
	display: none;
}

.location_text.active {
	display: block;
}

.tab-box {
	text-align: center;
}

.tab_btn {
	left: auto;
	right: auto;
	position: relative;
	background: none;
	border: none;
	font-size: 1rem;
	color: #ababab;
	text-shadow: 2px 1px 2px rgb(0, 20, 0);
	padding: .5rem;
	cursor: pointer;
}

.tab_btn.active {
	color: var(--white-green);
}

.tab_btn.active::after {
	transform: scale(1, 1);	
}

.tab_btn::after {
	content: '';
	position: absolute;
	width: 100%;
	height: .175rem;
	left: 0;
	bottom: 0;
	background: var(--white-green);
	transform: scale(0, 1);
	transition: transform 0.3s ease;
	border-radius: 3px;
}

.tab_btn:hover::after {
	transform: scale(1, 1);
}

.tab_btn:hover {
	color: var(--white-green);
	transition: 0.4s;
}

/*Page Zgodovina*/

.process-wrapper {
	margin:auto;
}

#progress-bar-container {
	position:relative;
	width:90%;
	margin:auto;
	height:100px;
	margin-top:65px;
}

#progress-bar-container ul {
	padding:0;
	margin:0;
	padding-top:15px;
	z-index:10;
	position:absolute;
	width:100%;
	margin-top:-40px
}

#progress-bar-container li:before {
	content:" ";
	display:block;
    padding: auto;
	margin:auto;
	width:30px;
	height:30px;
	border-radius:50%;	
	border:solid 2px #aaa;
	transition:all ease 0.3s;
     
}

#progress-bar-container li.active:before, #progress-bar-container li:hover:before {
	border:solid 2px #fff;
	background: radial-gradient(#7A985A, #94b871); 
}

#progress-bar-container li {
	list-style:none;
	float:left;
	width:14.28%;
	text-align:center;
	color:var(--white-green);
	text-transform:uppercase;
	font-size:11px;
	cursor:pointer;
	font-weight:700;
	transition:all ease 0.2s;
	vertical-align:bottom;
	height:60px;
	position:relative;
}

@media (max-width: 410px) {

	#progress-bar-container li .step-inner {
		top: 30px;
		height:45px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

#progress-bar-container li .step-inner {
	position:absolute;
	width:100%;
	bottom:0;
    font-size: 14px;
}

#progress-bar-container li.active, #progress-bar-container li:hover {
	color:var(--light-green);
	text-shadow: 1px 1px 3px rgb(172, 193, 168)
}

#progress-bar-container li:after {
	content:" ";
	display:block;
	width:6px;
	height:6px;
	background:#777;
	margin:auto;
	border:solid 7px var(--white-green);
	border-radius:50%;
	margin-top:40px;
	box-shadow:0 2px 13px -1px rgba(0,0,0,0.3);
	transition:all ease 0.2s; 
}

#progress-bar-container li:hover:after {
	background:#555;
}

#progress-bar-container li.active:after {
	background: var(--light-green);
}
#progress-bar-container #line {
	width:86%;
	margin:auto;
	background: var(--white-green);
	height:6px;
	position:absolute;
	left:7%;
	top:55px;
	z-index:1;
	border-radius:50px;
	transition:all ease 0.9s;
}

#progress-bar-container #line-progress {
	content:" ";
	width:3%;
	height:100%;
	background: var(--light-green);	 
	background: linear-gradient(to right, #7A985A 0%,#9bc173 100%); 
	position:absolute;
	z-index:2;
	border-radius:50px;
	transition:all ease 0.9s;
}

#progress-content-section {
	margin: auto;
    background-color: var(--background-blur);
    backdrop-filter: blur(.5rem);
}

#progress-content-section .section-content {
	padding: 1rem 10% 3rem 10%;
	text-align:center;
}

#progress-content-section .section-content h2 {
	font-size:1.7rem;
	text-transform:uppercase;
	color:var(--light-green);
	letter-spacing:1px;
	text-shadow: .5px .5px 3px rgb(0, 30, 0);
}

#progress-content-section .section-content p {
	text-align: justify;
	font-size: var(--fs-400);
	line-height:1.8em;
	color: var(--white-green);
}

#progress-content-section .section-content {
	display:none;
	animation: FadeInUp 700ms ease 1;
	animation-fill-mode:forwards;
	transform:translateY(15px);
	opacity:0;
}

#progress-content-section .section-content.active {
	display:block;
}

#history-credit {
	font-size: 0.8rem;
	text-align: center;
	margin: 1rem 0;
}

@keyframes FadeInUp {
	0% {
		transform:translateY(15px);
		opacity:0;
	}
	
	100% {
		transform:translateY(0px);
		opacity:1;
	}
}

/* Animal page */

#animal-into-text{
	margin-bottom: 0;
	color: var(--dark-green);
	text-shadow: 2px 1px 5px rgb(143, 143, 143);
}

.products-container{
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap:3rem;
	margin:0 auto;
	padding:3rem 2rem;
}

.product{
	width: 250px;
	height: 340px;
	position: relative;
	margin: auto;
}
	
.product img {
	border-radius: .5rem;
	border: solid 1px var(--dark-green);
	box-shadow: 1px 1px 4px rgb(0, 20, 0);
	width: 100%;
	height: 100%;
	display: block;
	margin: auto;
}
	
.product p {
	margin: 0;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	background-color: #295a1fa1;
	border-radius: .5rem;
	border: solid 1px var(--dark-green);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	opacity: 0;
	transition: 0.6s;
}
	
.product p:hover{
	opacity: 1;
}
 
.products-preview{
	bottom:0; left:0;
	width: 100%;
	display: none;
	align-items: center;
	justify-content: center;
}
 
.preview {
	display: none;
	top: 70%;
	padding: 15px 30px;
	text-align: center;
    background-color: var(--background-blur);
    backdrop-filter: blur(1rem);
	border:solid 1px #363f34;
	border-radius: 50px;
	position: absolute;
	margin:2rem;
	width: 80%;
	height: 500px;
	box-shadow: 1px 1px 4px rgb(0, 20, 0);
}

.preview h3 {
	font-size: 2rem;
}

.preview p {
	text-align: justify;
}
 
.preview.active{
	display: inline-block;		
}
 
.fa-times {
	position: absolute;
	top: 2rem; right: 3rem;
	font-size: 35px;
	color: var(--white-green);
	margin: 0;
	padding: 0;
	font-weight: 600;
}

.fa-times:hover {
	color: #a0af9d;
}

.fa-times:active{
	transform: scale(0.8);
}

@media (max-width:1500px) {
	.products-container{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	}
}

@media (max-width:1300px) {
	.products-container{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	}
}

@media (max-width:110px) {
	.preview {
		top: 100%;
	}
}

@media (max-width:900px) {
	.product{
		width: 170px;
		height: 240px;
	}
}

@media (max-width:655px) {
	.product{
		width: 170px;
		height: 240px;
	}

	.products-container{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width:460px) {
	.product{
		width: 130px;
		height: 200px;
	}
	.preview {
		top: 95%;
		height: 710px;
	}
}

@media (max-width:450px) {
	.preview {
		top: 100%;
	}
}

/* Kontant page */

.content-section_kontakt {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 0 7%;
	margin-bottom: 3rem;
}

.content-section_kontakt_item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    padding: 1rem 2.5rem;
    background-color: var(--background-blur);
    backdrop-filter: blur(.5rem);
    margin-bottom: 1rem;
	width: 280px;
	height: 270px;
	text-align: center;
	margin: 0;
	justify-self: center;
}

.content-section_kontakt_item p {
	margin: 0;
	line-height: 2.3;
	font-size: clamp(1rem, 1.25vw, 1.3rem);
}

@media (max-width:1320px) {

	.content-section_kontakt {
		grid-template-columns: repeat(2, 1fr);
		row-gap: 3rem;
	}

	.content-section_kontakt_item {
		width: 250px;
	}

	#location_info {
		grid-column: span 2;
	}

}

@media (max-width:850px) {

	.content-section_kontakt {
		grid-template-columns: repeat(1, 1fr);
		row-gap: 3rem;
	}

	#location_info, #email, #person {
		grid-column: span 1;
		width: 220px;
		justify-self: center;
	}
}

.icon {
	height: 50%;
}

#icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 90px;
	height: 90px;
	background-color: var(--background-blur);
    backdrop-filter: blur(.3rem);
	border: solid 4px var(--white-green);
	box-shadow: 2px 1px 5px rgb(0, 20, 0); 
	border-radius: 50px;
	margin-bottom: 2rem;
	margin-top: 0.7rem;	
}

.fa-map-marker-alt, .fa-phone, .fa-envelope {
	font-size: 45px;
}

.kontakt_text {
	height: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* Pripravniki page */

#pripravniki-text{
	color: var(--dark-green);
	text-shadow: 2px 1px 5px rgb(143, 143, 143);
}

.download {
	color: #114c36;
}

#plan{
	width: 85%;
	margin: 2em auto 3em;
	border: solid 2px var(--light-green);
	border-collapse: collapse;
	background-color: var(--background-blur);
	backdrop-filter: blur(.3rem);
	box-shadow: 2px 1px 5px rgb(0, 20, 0); 
	text-align: center;
	font-size: var(--fs-200);
}

table, tr, th, td{
	border: solid 2px var(--light-green);
	margin: 0;
	padding: 0;
	padding: 4px 7px;
}

#table-header {
	width: 100%;
}

#mesec {
	width: 20%;
}

#time {
	width: 7%;
}

.activity_text {
	text-align: start;
}

/*
.dropbox{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

.drag-area{
  border: 2px dashed var(--white-green);
  height: 200px;
  width: 500px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
  margin-top: 3rem;
}
.drag-area.active{
  border: 2px solid var(--white-green);
}
.drag-area .icon{
  font-size: 50px;
  color: var(--white-green);
}
.drag-area header{
  font-size: 20px;
  font-weight: 500;
  color: var(--white-green);	
}
.drag-area span{
  font-size: 17px;
  font-weight: 500;
  color: var(--white-green);
  margin: 10px 0 15px 0;
}
.drag-area button{
  padding: 10px 25px;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 20px;
  border: none;
  outline: none;
  background: var(--white-green);
  color: var(--dark-green);
  border-radius: 5px;
  cursor: pointer;
}
.drag-area img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
*/

/* Login page */

.center {
	font-family: 'Roboto Slab', serif;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	height: 300px;
	box-shadow: 2px 2px 5px rgb(0, 20, 0);
    background-color: #e7eed525;
    backdrop-filter: blur(.5rem);
	border:solid 1px #363f34;
	border-radius: 1rem;
}

.center form{
	padding: 0 40px;
	box-sizing: border-box;
}

form .txt_field{
	position: relative;
	border-bottom: 2px solid #363f34;
	margin: 30px 0;
}

.txt_field input{
	width: 100%;
	padding: 0 5px;
	height: 40px;
	font-size: 16px;
	border: none;
	background: none;
	outline: none;
}

.txt_field label{
	position: absolute;
	top: 50%;
	left: 5px;
	color: var(--white-green);
	transform: translateY(-50%);
	font-size: 20px;
	pointer-events: none;
	transition: .5s;
}
  
.txt_field span::before{
	content: '';
	position: absolute;
	top: 40px;
	left: 0;
	width: 0%;
	height: 2px;
	background: var(--dark-green);
	transition: .5s;
}
 
.txt_field input:focus ~ label, 
.txt_field input:valid ~ label{
	top: -5px;
	color: var(--dark-green); 
}
  
.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before{
	width: 100%;
}
  
.pass{
	margin: -5px 0 20px 5px;
	color: var(--white-green);
	cursor: pointer;
}
  
.pass:hover{
	text-decoration: underline;
}
  
input[type="submit"]{
	width: 100%;
	height: 50px;
	border: 1.5px solid var(--dark-green);
	background: var(--dark-green);
	border-radius: 25px;
	font-size: 18px;
	color: var(--white-green);
	font-weight: 700;
	cursor: pointer;
	outline: none;
}
  
input[type="submit"]:hover{
	border-color: var(--dark-green);
	transition: .5s;
}
