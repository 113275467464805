#footer{
    width: 100%;
    margin: 0;
    background-color: #0a3b29cb;
    backdrop-filter: blur(.5rem);
    color: #f8f6f1;
    display: flex;
    justify-content: center;
    z-index: 1;
}

.footer_text{
    text-align: center;
    padding: 20px 10%;
    text-align: start;
    font-size: clamp(.8rem, 1.25vw, 1.1rem);
}

.footer_text h4{
    font-size: large;
    margin: 0;
}

#contact p{
    line-height: 2;
    margin: 0;
}
    
