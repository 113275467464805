@import "base/typography";


.nav-bar{
    background-color: #0a3b29cb;
    backdrop-filter: blur(1rem);
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    z-index: 1000;
}

.nav-bar ul {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

#header{
    text-align: center;
}

#header-page img{
    display: none;
}

.nav-bar li {
    width: 160px;
    list-style-type: none;   
    text-align: center;    
    padding: 0px; 
    text-shadow: 2px 1px 5px rgb(0, 20, 0);  
}

.nav-bar li a {
    font-size: large;
    color: #e1eedf;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.3s ease 0s;
}

.nav-bar li a:hover {
    color: #7A985A;
}

@media (max-width: 1320px) {
    .nav-bar li {
        width: 150px;
    }
}

@media (max-width: 1250px) {
    .nav-bar li {
        width: 140px;
    }
}

@media (max-width: 1190px) {
    .nav-bar li {
        width: 130px;
    }
}

.login{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 9999;
    top: .35rem;
    right: 2rem;
    margin: 0;
}

@media (min-width: 1140px) {
    .login{
        position: fixed;
    }
}

.login p{
    margin: 0;
    padding-right: 10px;
    font-size: large;
    color: #e1eedf;
    text-shadow: 2px 1px 5px rgb(0, 20, 0);
    font-weight: 300;
    text-decoration: none;
    transition: all 0.3s ease 0s;
}

.login:hover {
    scale: 1.05;
}


.login-btn-img{
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("/assets/img/Login.svg");
    background-color: transparent;
    border: none;
    z-index: 9999;
    width: 2rem;
    aspect-ratio: 1;
    filter: drop-shadow(0px 0px 2px #e1eedf);
}


.mobile-nav-toggle {
    display: none;
}

@media (max-width: 1140px){
    .mobile-nav-toggle{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 9999;
        top: 2rem;
        left: 2rem;
        width: 2rem;
        aspect-ratio: 1;
        border: none;
        background-color: transparent;
    }
    
    .hamburger {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 30px;
        height: 5px;
        background: #114c36;
        border-radius: 5px;
    }

    .hamburger::before, 
    .hamburger::after {
        content: '';
        position: absolute;
        right: 0;
        width: 30px;
        height: 5px;
        background: #114c36;
        border-radius: 5px;
    }


    .hamburger::before { 
        transform: translateY(-11px); 
    }

    .hamburger::after { 
        transform: translateY(11px);
    }

    .nav-bar{
        overflow: hidden;     
        height: 270px;
        position: fixed;
        width: 100%;
        background: #0d36267a;
        backdrop-filter: blur(1rem);
        transition: 0.2s;
        transform: translateY(-100%);  
    }

    .nav-bar[data-visible="true"] {
        transform: translateY(0%);        
    }  

    .nav-bar ul{
        display: block;
        width: fit-content;
        transition: 0.5s;
        opacity: 1;
        padding: 0;
    }


    .nav-bar ul li{
        margin: 15px 0;
    }

    .login{
        top: 2rem;
    }
}