:root{
    --fs-xl: clamp(2.5rem, 7vw, 3.5rem);
    --fs-800: clamp(2.5rem, 7vw, 3rem);
    --fs-600: clamp(1rem, 2vw, 1.3rem);
    --fs-400: clamp(1rem, 2vw, 1.2rem);
    --fs-200: clamp(0.7rem, 1.5vw, 1rem);
}

body {
    font-family: 'Roboto Slab', serif;
}

h3{
    text-align: center;
}

/*Home page - typography*/

.title-main {
    font-size: var(--fs-xl);
    font-weight: bold;
    text-align: center;
    padding: 0 25%;
    margin: 0 0 3rem 0;
}

.content-main  {
    font-size: var(--fs-600);   
    font-weight: bold;
    text-align: center;
    line-height: 2;
    padding: 0 20%;
    margin: 0 0 40px 0;
    vertical-align: middle;  
}

.content-main p{
    margin: 0;
}

@media (max-width: 900px){
    .content-main  {
        padding: 0 15%; 
    }
}

@media (max-width: 600px){
    .content-main  {
        padding: 0 10%; 
    }
}

/*Page - typography*/

.content{
    width: 100%;
    font-size: var(--fs-400);
    line-height: 1.5;
}

.content p {
    margin-bottom: 0;
}

mark {
    color: #7A985A;
    background-color: transparent;
}


.title {
    font-weight: bold;
    text-align: center;
    margin: 0;
    color: #114c36;
    text-shadow: 2px 1px 5px rgb(143, 143, 143);
}

.title h1{
    font-size: var(--fs-800);
    margin: 0 0 2rem 0;
    padding-top: 4rem;
}

.content-section {
    line-height: 2;    
    text-align: justify;
}


#contact{
    line-height: 1.5;
}

