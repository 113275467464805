body{
    width: 100%;
    overflow-y: scroll;
}

footer {
    margin-top: auto;
}

.wrapper-main{   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f8f6f1;
    text-shadow: 2px 1px 5px rgb(0, 20, 0); 
}

.wrapper {   
    color: #f8f6f1;
    text-shadow: 2px 1px 3px rgb(0, 20, 0); 
}


